<template>
  <div class="footer">
    <div class="footer_top">
      <div>
        <span @click="goProducts" style="cursor: pointer;">产品服务</span>
        <span>品牌业务</span>
        <span @click="goAbout" style="cursor: pointer;">关注我们</span>
      </div>
    </div>
    <div class="footer_con">
      <div class="con_left">
        <div class="product-con">
          <div v-for="(item,index) in productList" :key="index">
            <p class="word-con-word" @click="goProduct(item)">{{item.title}}-{{item.description}}</p>
          </div>
        </div>
      </div>
      <div class="con_right">
        <div v-for="(item,index) in qrCodelist" :key="index">
          <p>{{item.title}}</p>
          <img class="img_con" :src="item.codeAddress" alt="">
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <a href="http://beian.miit.gov.cn/">CopyRight 2018 北京人和万线科技开发有限公司 京ICP备2024097848号</a>
    </div>
  </div>
</template>

<script>
import { getProduct,getQrCode } from "@/api/common";
export default {
  name: "Footer",
  props: {
    num: null,
    current: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      productList: [],
      qrCodelist: []
    };
  },
  methods: {
    goProducts() {
      this.$router.push('/productsHome')
    },
    goProduct(val) {
      if(this.current){
        this.$emit("clickFooter", val)
      }else {
        this.$router.push({
          path: '/products',
          query: {
            index: val.index,
            id: val.id
          }
        })
      }
    },
    goAbout() {
      this.$router.push('/aboutWanxian')
    },
    // 产品服务列表
    getProduct() {
      let params = {};
      getProduct(params).then((res) => {
        if (res.data.code == 200) {
          res.data.data.map((item,index)=>{
            if(item.useType == "1"){
              item.index = index
              this.productList.push(item)
            }
          })
        } else {
        }
      });
    },
    // 获取二维码
    getQrCode() {
      let params = {};
      getQrCode(params).then((res) => {
        if (res.data.code == 200) {
          this.qrCodelist = res.data.data
        } else {
        }
      });
    },
  },
  mounted() {
    this.getProduct()
    this.getQrCode()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer_top {
  width: 100%;
  height: 98px;
  background: #008CD7;
  overflow: hidden;
  div {
    width: 1280px;
    height: 98px;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.footer_con {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 150px;
  color: #333333;
  font-size: 20px;
  font-family: MicrosoftYaHei;
  justify-content: space-between;
  .con_left {
    p {
      margin-top: 40px;
      cursor: pointer;
    }
    .span_color {
      color: #008CD7;
    }
    .span {
      cursor: pointer;
    }
  }
  .con_right {
    display: flex;
    padding: 60px 0px 0px 80px;
    .img_con {
      width: 168px;
      height: 168px;
      margin-right: 30px;
      margin-top: 10px;
      display: block;
    }
  }
}
.product-con {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .word-con-word {
    width: 240px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}
.footer_bottom {
  width: 100%;
  height: 98px;
  background: #333333;
  text-align: center;
  line-height: 98px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  a {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    text-decoration:none
  }
}
</style>
